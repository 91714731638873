<div class="coupon-layout google-coupon cn fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="adcore-logo"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
    <a class="google-tag" href="https://www.google.com/partners/agency?id=3987411150" target="_blank"><i></i></a>
    <div class="content">
      <span>在两个简单步骤中获得2024年</span>
      <div><span class="bold">$500</span>的 Google广告优惠券</div>
    </div>
    <div class="google-eligible-btn"><button (click)="goToEligibleLink('google')">仅适用于符合条件的账户</button></div>
    <div class="link-acc">
        <app-link-steps [step1Text]="'关联您的 Google Ads 帐户'" [step2Text]="'兑换您的 3000 港币优惠券'" [stepMiddleText]="'Checking your eligibility...'" [showMiddleStep]="loading" [accountLinked]="loading"></app-link-steps>
        <div class="buttons">
            <div *ngIf="!loading">
                <div><p-button styleClass="link-btn bold" label="关联您的 Google Ads 帐户" (onClick)="openRecaptcha('link')"></p-button></div>
                <div class="or">或者</div>
                <div class="container create-btn">
                  <div class="clickthrough" >创建新账户</div>
                  <div class="hide"> <asl-google-signin-button type='standard' size='medium' logo_alignment="center"></asl-google-signin-button></div>
                </div>
            </div>
            <div class="loader-box acc" *ngIf="loading">
              <div class="acc-loader-blue"></div>
            </div>
        </div>
   </div>
  <app-metrics [merticData]="metricData"></app-metrics>
  <app-video-player videoPath="https://www.youtube.com/embed/i4kV_V0qby4"></app-video-player>
  <app-powered [lang]="'cn'" (linkBtnEvent)="openRecaptcha('link')" [isFirstStep]="true" [type]="'google'"></app-powered>
  <app-questions [listItemsType]="'cnGoogle'" [title]="'常见问题'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>
  <app-footer [lang]="'cn'"></app-footer>
  </div>
</div>
<p-dialog [(visible)]="showDialog" styleClass="create-acc-dialog" [modal]="true" [blockScroll]="true">
  <ng-template pTemplate="header">
    {{dialogHeader}}
    <i class="success-icon" *ngIf="dialogHeader === 'Account created successfully'"></i>
  </ng-template>
  <p>{{dialogBody}}</p>
  <ng-template pTemplate="footer">
    <p-button *ngIf="redeemButton" label="Got it" [disabled]="loading" (onClick)="got_it('create')" styleClass="primary-btn"></p-button>
  </ng-template>
</p-dialog>
<p-toast key="msgTemplate" position="center" (onClose)="close($event)">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message" (confirm)="got_it('link')">
      <div *ngIf="message.detail === 'Success'">
        <p>该账户已经链接到Couponer的经理账户。</p>
        <p><a style="text-decoration: underline; cursor:pointer" (click)="linkAccount()">链接另一个账户</a> 或 <a style="text-decoration: underline; cursor:pointer" (click)="clearToast()">创建一个新账户</a></p>
      </div>
      <div *ngIf="message.detail === 'Active'">
        <p>您的Google广告账户未激活，请<a href='https://support.google.com/google-ads/answer/6366743?hl=en&ref_topic=6375057' target="_blank" (click)="clearToast()">修复</a>或<a style="text-decoration: underline; cursor:pointer" (click)="clearToast()">创建新账户</a>。</p>
      </div>
      <div *ngIf="message.detail !== 'Success' && message.detail !== 'Active'"><p>{{message.data}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
