<div class="coupon-layout google-coupon in-layout-addition step2 fixed-header">
    <app-header></app-header>
    <div class="main-content">
        <a class="in-tag logo-via-mic"><i></i><span>via</span><i></i></a>
        <div class="content">
            <span>Get <span class="sign bold">$</span><span class="bold">400</span> LinkedIn* 2024</span> &nbsp;
            <span>coupon in two easy steps</span>
        </div>
        <div class="google-eligible-btn"><button (click)="goToEligibleLink('microsoft')">For eligible accounts only</button></div>
        <div class="link-acc">
            <app-link-steps [accountLinked]="true" [step1Text]="'Link your'" [step1SecText]="'Microsoft Ads account'" [step2Text]="'Redeem your'" [step2SecText]="'$400 coupon'" [stepMiddleText]="'Checked'" [showMiddleStep]="true" [accountLinked]="true" [isMiddleChaked]="true"></app-link-steps>
            <div class="buttons">
                <div class="additional-text">
                    <div>*This coupon is for Microsoft Advertising's</div>
                    <div>LinkedIn profile targeting.</div>
                </div>
                <div>
                    <p-button styleClass="link-btn" label="Redeem your $400 coupon" (onClick)="RedeemCoupon()" [disabled]="loading">
                        <div></div>
                    </p-button>
                    <div class="loader-box green" *ngIf="loading">
                        <div class="loader">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-metrics [merticData]="metricData"></app-metrics>
        <app-powered [lang]="'en'" [type]="'microsoft'"></app-powered>
        <div *ngIf="!showCsData">
            <app-questions [listItemsType]="'microsoft'" [title]="'Q&A'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>   
        </div>
        <app-footer [lang]="'en'"></app-footer>
    </div>
</div>
<p-toast key="msgTemplate" position="center">
<ng-template let-message pTemplate="message">
<app-msg-template [message]="message" (confirm)="got_it()">
  <div><p>{{message.data}}</p></div>
</app-msg-template>
</ng-template>
</p-toast>
