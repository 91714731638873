<div>
  <div *ngIf="!verified">
    <div *ngIf="!sendVerifyError">
      <div>
        <div class="text-center mb-3">
          <div>We’ve sent a 6-digit code to your email: </div>
          <div>{{user?.email}}</div>
          <div>Enter the code below to continue.</div>
        </div>
        <div class="field fluid mb-3" [ngClass]="{'invalid-border': codeError}">
          <div class="card flex justify-content-center">
            <p-inputOtp [(ngModel)]="code" [integerOnly]="true" [length]="6" class="verify-code" (onChange)="onCodeChange()"/>
          </div>
          <small class="login-error mb-1" *ngIf="codeError">Invalid verification code</small>
          <small class="mb-1" *ngIf="showResentResult">{{resentResult}}</small>
          <div class="action-container">
            <div class="resend-container">
              <p-button label="Resend code" styleClass="p-button-link" (onClick)="send2faRequest()" [disabled]="inProcess || resendTimes >= 6 || isResendDisabled"></p-button>
              <div *ngIf="isResendDisabled">({{countdown}} seconds)</div>
            </div>
            <p-button label="Submit Code" styleClass="submit-code-button" (onClick)="submit()" [disabled]="inProcess" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="sendVerifyError">
      An error occur when sending the email verification code!
    </div>
  </div>
  <div *ngIf="verified">
    <div *ngIf="!loading">
      <h4> Don't have a {{channelMsg}} Ads account yet?</h4>
      <div class="field">
        <h5>BUSINESS DETAILS</h5>
        <div class="field">
          <p-chips [(ngModel)]="brandNames" placeholder="Business name" [allowDuplicate]="false"
                   [addOnBlur]="true" styleClass="tags"></p-chips>
        </div>
        <div class="field">
          <input id="website" name="website" type="text" pInputText placeholder="Business website"
                 [(ngModel)]="website" autocomplete="off" [required]="true" (keyup)="removeError()"/>
        </div>
        <div class="field">
          <p-dropdown styleClass="w-100" panelStyleClass="create-acc-panel" [options]="businessTypes" [(ngModel)]="businessType" optionLabel="label"
                      optionValue="value" placeholder="business Type"></p-dropdown>
        </div>
      </div>
      <div>
        <h5>ACCOUNT DETAILS</h5>
        <div class="field">
          <input type="text" id="accountName" name="accountName" pInputText [(ngModel)]="accountName" placeholder="Account name">
        </div>
        <div class="middle-input field">
          <p-dropdown [required]="true" id="currency" placeholder="Choose your currency" [options]="currencies"
                      [(ngModel)]="selectedCurrency" [filter]="true" optionLabel="label" optionValue="value" filterBy="value"
                      filterPlaceholder="Search..." panelStyleClass="create-acc-panel"></p-dropdown>
        </div>
        <div class="field">
          <p-dropdown  [required]="true" id="timezone" placeholder="Choose your timezone" [options]="timezones"
                       [(ngModel)]="selectedTimezone" [filter]="true" optionLabel="label" optionValue="value" filterBy="value"
                       filterPlaceholder="Search..." panelStyleClass="create-acc-panel"></p-dropdown>
        </div>
        <div class="field">
          <p-dropdown styleClass="w-100" [options]="eus" [(ngModel)]="eu" optionLabel="label" [disabled]="loading" panelStyleClass="create-acc-panel"
                      optionValue="value" placeholder="Account geo targetting"></p-dropdown>
        </div>
      </div>

      <div class="buttons">
        <small class="login-error mb-1" id="website-error" *ngIf="websiteError">{{websiteError}}</small>
        <p-button [label]="!loading ? 'Create account' : ''" [disabled]="disabled" (onClick)="createAccount()" styleClass="primary-btn">
          <div class="loader-box" *ngIf="loading">
            <div class="loader">
              <div></div>
            </div>
          </div>
        </p-button>
        <p-button label="Cancel" (onClick)="cancel()" styleClass="secondary-btn"></p-button>
      </div>
    </div>
    <div class="loader-box blue" *ngIf="loading">
      <div class="loader">
          <div></div>
      </div>
  </div>
  </div>
</div>
