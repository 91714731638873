import {Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../../../environments/environment';
import {takeUntil, Subject, lastValueFrom} from "rxjs";
import {MessageService} from 'primeng/api';
import {WindowRefService} from "../../helpers/windowref.service";
import {DialogService} from 'primeng/dynamicdialog';
import {CreateGoogleAdsAccountComponent} from "../../shared/dialogs/create-google-ads-account/create-google-ads-account.component";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { SocialUser } from "@abacritt/angularx-social-login";
import {ApiCallService} from "../../helpers/api-call.service";
import {VerifyCodeComponent} from "../../shared/verify-code/verify-code.component";
import { NavigationService } from '../../helpers/navigation.service'
import translation from "../../google-coupon/google-coupon-step1/messageTranslation.json";
import { DataServiceService } from '../../helpers/data.service';
import { ConfigService } from '../../helpers/config.service';

@Component({
  selector: 'app-youtube-coupon-step1',
  templateUrl: './youtube-coupon-step1.component.html',
  styleUrl: './youtube-coupon-step1.component.scss'
})

export class YoutubeCouponStep1Component implements OnInit, OnDestroy {
  accountLinked:boolean = false;
  openTabIndex:any = undefined;
  customerId: string | null = null;
  showDialog: boolean = false;
  dialogHeader: string = '';
  dialogBody: string = 'An invitation was sent to your mailbox.';
  redeemButton: boolean = true;
  user: SocialUser | undefined;
  loading: boolean = false;
  geo: string = 'Global';
  score: number | undefined;
  receiveMsgFlag = false;
  metricData:any = {
    link: {
      amount: '3,000',
      text: 'Accounts Linked',
    },
    redeemed: {
      amount: '2,250',
      text: 'Coupons Redeemed',
    },
    saved: {
      amount: '$955,000',
      text: 'Saved',
    }
  };
  page = 'youtube';

  componentDestroyed: Subject<boolean> = new Subject();
  private handler: any;
  csData:any = {};
  showCsData:boolean = true;
  constructor(private router: Router, private authService: SocialAuthService,
              private messageService: MessageService, public dialogService: DialogService,
              private winRef: WindowRefService,
              private apiCallService: ApiCallService, private navigationService: NavigationService,
              public data:DataServiceService, public cs: ConfigService) {
                this.data.setHeaderMenuSelection('10');
              }

  ngOnInit(): void {
    this.init();
    this.handler = this.receiveMessage.bind(this);
    this.authService.authState.pipe(takeUntil(this.componentDestroyed)).subscribe((user) => {
      this.user = user;
      if (user) {
        this.checkUserSession();
      }
    });
    document.body.scrollTop = 0;
    this.getCsData();
    this.startPolling()
  }

  async getCsData(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('youtube', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;

    setTimeout(() => {
      this.showCsData = false;
      console.log('csData ' +this.csData.thankYouHeader);
    }, 100);
  }

  goToEligibleLink(channel:string) {
    this.data.goToEligibleLink(channel);
   }

  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

  async checkUserSession() {
    // If there email already have ongoing creation process in db
    this.loading = true;
    const params = {
      user: this.user,
      sessionToken: sessionStorage.getItem('youtubeToken'),
      page: 'youtube'
    };
    const checkUserSession$ = this.apiCallService.checkUserSession(params, 'google');
    const response = await lastValueFrom(checkUserSession$);
    if (response.result && response.result === 'ok') {
      this.openRecaptcha('create');
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: 'You have reached the account creation limit for Youtube and cannot create additional accounts.',
        data: 'You have reached the account creation limit for Youtube and cannot create additional accounts.',
        closable: true,
        sticky: true
      });
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.user) {
      this.authService.signOut();
    }
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  get platformURL(): string {
    return `${environment.apiUrl}/v1/google_platform/login`

  }

  async init(): Promise<void> {
    const init$ = this.apiCallService.init('youtube');
    const response = await lastValueFrom(init$);
    if (response.token) {
      sessionStorage.setItem('youtubeToken', response.token);
    }
  }

  createAccount(): void {
    const ref = this.dialogService.open(CreateGoogleAdsAccountComponent, {
      header: 'Create Account',
      data: {user: this.user, geo: this.geo, channel: 'google', recaptchaScore: this.score, page: 'youtube'},
      styleClass: 'create-acc-dialog create-acc-form-dialog',
      modal: true
    });
    ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((returnData: any) => {
      if (returnData.result === 'verifying') {
        this.messageService.clear();
        this.showDialog = true;
        this.dialogHeader = 'CREATE ACCOUNT';
        this.dialogBody = 'We are verifying your business\'s eligibility for advertising. You will receive an email ' +
          'notification once the verification process is complete.\n';
        this.redeemButton = true;
      } else {
        this.messageService.clear();
        this.showDialog = true;
        this.dialogHeader = 'CREATE ACCOUNT ERROR';
        if (returnData.error) {
          console.error(returnData.error);
        }
        this.dialogBody = 'We meet error creating account. Please try again later.';
        this.redeemButton = false;
      }
    })
  }

  cancel(): void {
    this.user = undefined;
    this.customerId = null;
    this.showDialog = false;
  }

  async openRecaptcha(op: string): Promise<void> {
    const ipValidate$ = this.apiCallService.ipValidate('youtube');
    const response = await lastValueFrom(ipValidate$);
    if (response.enabled) {
      const token = await this.apiCallService.executeRecaptcha(op);
      if (token) {
        const params = {
          token: token,
          sessionToken: sessionStorage.getItem('youtubeToken'),
          action: op
        };
        await this.getRecaptcha(params, op);
      }
    }
    else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "You've hit today's limit for account linking. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!.",
        data: "You've hit today's limit for account linking. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!",
        closable: true,
        sticky: true
      });
    }
  }

  async getRecaptcha(params: any, op: string) {
    const recaptchaAssessment = this.apiCallService.recaptchaAssessment(params);
    const recaptcha_response = await lastValueFrom(recaptchaAssessment);
    this.score = recaptcha_response.score;
    if (this.score && this.score >= 0.8) {
      if (op === 'link') {
        this.linkAccount();
      }
      if (op === "create") {
        this.createAccount();
      }
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "High risk indicated by Google reCAPTCHA, please contact us.",
        data: "High risk indicated by Google reCAPTCHA, please contact us.",
        closable: true,
        sticky: true
      });
    }
  }

  linkAccount(): void {
    this.loading = true;
    this.messageService.clear();
    this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
    this.winRef.reportGtagEventNew('clickOnLinkAccount');
    const params = this.winRef.queryString;
    const suffixUrl = `geo=${this.geo}&score=${this.score}&page=youtube`;
    const loginUrl = params.length ? this.platformURL + '/' + params + `&${suffixUrl}` : this.platformURL + `/?${suffixUrl}`;
    const wndParams = `scrollbars=yes,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,top=50,left=200`;
    const newWindow = this.winRef.nativeWindow.open(loginUrl, 'Authenticate', wndParams);

    // Polling to check if the window has been closed
    const checkWindowClosedInterval = setInterval(() => {
      if (newWindow && newWindow.closed) {
        clearInterval(checkWindowClosedInterval);
        if (!this.receiveMsgFlag) {
          this.loading = false;
          // Perform any additional cleanup or actions needed when window is closed
          console.log('Authentication window closed by user.');
          // Optionally, remove the event listener if it's no longer needed
          this.winRef.nativeWindow.removeEventListener('message', this.handler);
        }
        this.receiveMsgFlag = false;
      }
    }, 1000); // Check every second

    this.winRef.nativeWindow.addEventListener('message', this.handler);
  }

  receiveMessage(event: any): any {
    // console.log('ga receiveMessage:', {event});
    this.messageService.clear();
    if (event.origin === `${environment.apiUrl}` && event.data && event.data.refresh_token) {
      this.receiveMsgFlag = true;
      this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
      let error = event.data.error;
      if (event.data.taskId) {
        this.showVerifyCode(event.data);
      } else {
        this.loading = false;
        this.messageService.add({
          key: 'msgTemplate',
          severity: 'error',
          summary: '',
          detail: error,
          data:error,
          closable: true,
          sticky: true
        });
      }
    }
  }

  private showVerifyCode(verifyInfo: any): void {
    const ref = this.dialogService.open(VerifyCodeComponent, {
      header: 'Verify Email',
      data: verifyInfo,
      styleClass: 'verify-code-dialog create-acc-form-dialog',
      modal: true
    });
    ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((returnData: any) => {
      if (returnData) {
        this.apiCallService.ytLinkTaskId = returnData.taskId;
        sessionStorage.setItem('youtubeInfo', JSON.stringify(returnData));
        const params = {
          ...returnData,
          sessionToken : sessionStorage.getItem('youtubeToken'),
          geo: this.geo,
          page: this.page
        }
        this.apiCallService.startSafeguardTask(params, 'google');
        this.startPolling();
      } else {
        this.loading = false;
      }
    });
  }

  private startPolling() {
    if (this.apiCallService.ytLinkTaskId && this.apiCallService.ytLinkTaskId.length) {
      const taskId = this.apiCallService.ytLinkTaskId;
      this.loading = true;
      let timeout = true;
      this.apiCallService.statusPoll('youtube').pipe()
        .subscribe(
          {
            next: (res) => {
              timeout = false;
              if (res.status === 'completed') {
                sessionStorage.setItem('youtubePassedList', JSON.stringify(res.passedList));
                this.router.navigate(['/yt/step2']);
              } else {
                if (res.status === 'timeout') {
                  this.addTimeoutMsg()
                } else {
                  let error = res.error;
                  let severity = res.severity;
                  let summary = 'We apologize for the inconvenience.';
                  if (severity === 'success') {
                    summary = 'Great news!';
                  }
                  if (severity === 'warn') {
                    summary = '';
                  }
                  this.messageService.add({
                    key: 'msgTemplate',
                    severity: severity,
                    summary: summary,
                    detail: error,
                    data: translation[error as keyof typeof translation],
                    closable: true,
                    sticky: true
                  });
                }

              }
            },
            error: (reason) => {
              console.error({reason});
            },
            complete: () => {
              this.loading = false;
              this.apiCallService.deleteYtLinkTaskId();
              if (timeout) {
                this.addTimeoutMsg();
                this.apiCallService.timeoutTask(taskId, sessionStorage.getItem('youtubeToken')).subscribe();
              }
            }
          }
        );
    }
  }

  addTimeoutMsg() {
    this.messageService.add({
      key: 'msgTemplate',
      severity: 'error',
      summary: '',
      detail: 'timeout',
      data: translation['timeout' as keyof typeof translation],
      closable: true,
      sticky: true
    });
  }

  clearToast(): void {
    this.messageService.clear();
  }

  got_it(action: string): void {
    this.navigationService.canNavigateToStep3Youtube = true;
    this.router.navigate(['/yt/step3'], { queryParams: { action: action } });
  }

  close(event: any):void {
    if (event.message.severity === 'success') {
      this.got_it('link');
    }
  }
}

