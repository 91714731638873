<div class="coupon-layout google-coupon fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="adcore-logo"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
    <a class="google-tag" href="https://www.google.com/partners/agency?id=3987411150" target="_blank"><i></i></a>
    <div class="content">
        <span>Get <span class="sign bold">$</span><span class="bold">500</span> Google Ads 2024 </span>&nbsp;
        <span>coupon in two easy steps</span>
    </div>
    <div class="google-eligible-btn"><button (click)="goToEligibleLink('google')">For eligible accounts only</button></div>
    <div class="link-acc">
      <app-link-steps [step1Text]="'Link your Google Ads account'" [step2Text]="'Redeem your $500 coupon'" [stepMiddleText]="'Checked'" [showMiddleStep]="true" [accountLinked]="true" [isMiddleChaked]="true"></app-link-steps>
      <div class="buttons">
        <div>
          <p-button styleClass="link-btn" (onClick)="RedeemCoupon()" [disabled]="loading">
            <div></div>
          </p-button>
          <div class="loader-box green" *ngIf="loading">
            <div class="loader">
              <div></div>
            </div>
          </div>
        </div>
        <div class="text">To redeem the coupon, you need to connect your Google Ads account.</div>
      </div>
    </div>
    <app-metrics [merticData]="metricData"></app-metrics>
    <app-video-player videoPath="https://www.youtube.com/embed/i4kV_V0qby4"></app-video-player>
    <app-powered [lang]="'en'" [type]="'google'"></app-powered>
    <app-questions [listItemsType]="'google'" [title]="'Q&A'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>
    <app-footer [lang]="'en'"></app-footer>
  </div>
</div>
<p-toast key="msgTemplate" position="center">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message" (confirm)="got_it()">
      <div><p>{{message.data}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
