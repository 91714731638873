import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {takeUntil, Subject, lastValueFrom} from "rxjs";
import {MessageService} from "primeng/api";
import {WindowRefService} from "../../helpers/windowref.service";
import {DialogService} from 'primeng/dynamicdialog';
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {MicrosoftLoginProvider} from "@abacritt/angularx-social-login";
import {SocialUser} from "@abacritt/angularx-social-login";
import {
  CreateGoogleAdsAccountComponent
} from "../../shared/dialogs/create-google-ads-account/create-google-ads-account.component";
import {ApiCallService} from "../../helpers/api-call.service";
import { NavigationService } from '../../helpers/navigation.service'
import { DataServiceService } from 'src/app/helpers/data.service';
import {VerifyCodeComponent} from "../../shared/verify-code/verify-code.component";

@Component({
  selector: 'app-microsoft-coupon-step1',
  templateUrl: './microsoft-coupon-step1.component.html',
  styleUrls: ['./microsoft-coupon-step1.component.scss']
})
export class MicrosoftCouponStep1Component implements OnInit , OnDestroy {
  user: SocialUser | undefined;
  accountLinked: boolean = false;
  componentDestroyed: Subject<boolean> = new Subject();
  showDialog: boolean = false;
  dialogHeader: string = '';
  dialogBody: string = 'An invitation was sent to your mailbox.';
  redeemButton: boolean = true;
  loading: boolean = false;
  geo: string = 'Global';
  score: number | undefined;
  receiveMsgFlag = false;
  openTabIndex: any = undefined;
  metricData: any = {
    link: {
      amount: '2,850',
      text: 'Accounts Linked',
    },
    redeemed: {
      amount: '1,583',
      text: 'Coupons Redeemed',
    },
    saved: {
      amount: '$633,200',
      text: 'Saved',
    }
  };
  page = 'microsoft';
  public handler: any;

  constructor(private router: Router, public dialogService: DialogService,
              private messageService: MessageService, private authService: SocialAuthService,
              private navigationService: NavigationService,
              private winRef: WindowRefService, private apiCallService: ApiCallService, public data:DataServiceService)
  {
                this.data.setHeaderMenuSelection('3');
  }

  ngOnInit(): void {
    this.init();
    this.handler = this.receiveMessage.bind(this);
    document.body.scrollTop = 0;
    this.startPolling()
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  goToEligibleLink(channel:string) {
    this.data.goToEligibleLink(channel);
   }


  changeSelectedTabIndex(e: any) {
    if (e) {
      this.openTabIndex = e.index;
    }
  }

  async checkUserSession() {
    this.loading = true;
    const params = {
      user: this.user,
      sessionToken: sessionStorage.getItem(this.page + 'Token'),
      page: this.page
    };
    const checkUserSession$ = this.apiCallService.checkUserSession(params, 'microsoft');
    const response = await lastValueFrom(checkUserSession$);
    if (response.result && response.result === 'ok') {
      this.createAccount();
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: 'You have reached the account creation limit for Microsoft Ads and cannot create additional accounts.',
        data: 'You have reached the account creation limit for Microsoft Ads and cannot create additional accounts.',
        closable: true,
        sticky: true
      });
    }
    this.loading = false;
  }

  get platformURL(): string {
    return `${environment.apiUrl}/v1/microsoft_platform/login`
  }

  async init() {
    const init$ = this.apiCallService.init(this.page);
    const response = await lastValueFrom(init$);
    if (response.token) {
      sessionStorage.setItem(this.page + 'Token', response.token);
    }
  }

  signInWithMs(): void {
    this.authService.signIn(MicrosoftLoginProvider.PROVIDER_ID).then(
      (user) => {
        this.user = user;
        if (user) {
          this.checkUserSession();
        }
      }
    )
  }

  createAccount(): void {
    const ref = this.dialogService.open(CreateGoogleAdsAccountComponent, {
      header: 'Create Account',
      data: {user: this.user, geo: this.geo, channel: "microsoft", recaptchaScore: this.score, page: this.page},
      styleClass: 'create-acc-dialog create-acc-form-dialog',
      modal: true
    });
    ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((returnData: any) => {
      if (returnData.result === 'verifying') {
        this.messageService.clear();
        this.showDialog = true;
        this.dialogHeader = 'CREATE ACCOUNT';
        this.dialogBody = 'We are verifying your business\'s eligibility for advertising. You will receive an email ' +
          'notification once the verification process is complete.\n';
        this.redeemButton = true;
      } else {
        this.messageService.clear();
        this.showDialog = true;
        this.dialogHeader = 'CREATE ACCOUNT ERROR';
        if (returnData.error) {
          console.error(returnData.error);
        }
        this.dialogBody = 'We meet error creating account. Please try again later.';
        this.redeemButton = false;
      }
    });
  }

  async openRecaptcha(op: string): Promise<void> {
    const ipValidate$ = this.apiCallService.ipValidate(this.page);
    const response = await lastValueFrom(ipValidate$);
    if (response.enabled) {
      const token = await this.apiCallService.executeRecaptcha(op);
      if (token) {
        const params = {
          token: token,
          sessionToken: sessionStorage.getItem(this.page + 'Token'),
          action: op
        };
        await this.getRecaptcha(params, op);
      }
    }
    else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "You've hit today's limit for account linking. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!.",
        data: "You've hit today's limit for account linking. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!",
        closable: true,
        sticky: true
      });
    }
  }

  async getRecaptcha(params: any, op: string) {
    const recaptchaAssessment = this.apiCallService.recaptchaAssessment(params);
    const recaptcha_response = await lastValueFrom(recaptchaAssessment);
    this.score = recaptcha_response.score;
    if (this.score && this.score >= 0.8) {
      if (op === 'link') {
        this.linkAccount();
      }
      if (op === "create") {
        this.signInWithMs();
      }
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "High risk indicated by Google reCAPTCHA, please contact us.",
        data: "High risk indicated by Google reCAPTCHA, please contact us.",
        closable: true,
        sticky: true
      });
    }
  }

  linkAccount(): void {
    this.loading = true;
    this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
    this.winRef.reportGtagEventNew('clickOnLinkAccount');
    const params = this.winRef.queryString;
    const suffixUrl = `geo=${this.geo}&score=${this.score}&page=${this.page}`;
    const loginUrl = params.length ? this.platformURL + '/' + params + `&${suffixUrl}` : this.platformURL + `/?${suffixUrl}`;
    const wndParams = `scrollbars=yes,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,top=50,left=200`;
    const newWindow = this.winRef.nativeWindow.open(loginUrl, 'Authenticate', wndParams);

    // Polling to check if the window has been closed
    const checkWindowClosedInterval = setInterval(() => {
      if (newWindow && newWindow.closed) {
        clearInterval(checkWindowClosedInterval);
        if (!this.receiveMsgFlag) {
          this.loading = false;
          // Perform any additional cleanup or actions needed when window is closed
          console.log('Authentication window closed by user.');
          // Optionally, remove the event listener if it's no longer needed
          this.winRef.nativeWindow.removeEventListener('message', this.handler);
        }
        this.receiveMsgFlag = false;
      }
    }, 1000); // Check every second

    this.winRef.nativeWindow.addEventListener('message', this.handler);
  }

  receiveMessage(event: any): any {
    let detail = "Microsoft is having trouble connecting your account. Please try again later or contact couponer@adcore.com"
    let severity = 'error'
    let summary = 'We apologize for the inconvenience.'
    this.messageService.clear();
    if (event.origin === `${environment.apiUrl}` && event.data && event.data.refresh_token) {
      this.receiveMsgFlag = true;
      this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
      if (event.data.taskId) {
        this.showVerifyCode(event.data);
      } else {
        this.loading = false;
        this.messageService.add({
          key: 'msgTemplate',
          severity: severity,
          summary: summary,
          detail: detail,
          closable: true,
          sticky: true
        });
      }
    }
  }


  private showVerifyCode(verifyInfo: any): void {
    const ref = this.dialogService.open(VerifyCodeComponent, {
      header: 'Verify Email',
      data: verifyInfo,
      styleClass: 'verify-code-dialog create-acc-form-dialog',
      modal: true
    });
    ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((returnData: any) => {
      if (returnData) {
        this.apiCallService.msLinkTaskId = returnData.taskId;
        sessionStorage.setItem('microsoftInfo', JSON.stringify(returnData));
        const params = {
          ...returnData,
          sessionToken : sessionStorage.getItem(this.page + 'Token'),
          geo: this.geo,
          page: this.page
        }
        this.apiCallService.startSafeguardTask(params, 'microsoft');
        this.startPolling();
      } else {
        this.loading = false;
      }
    });
  }

  startPolling() {
    let detail = "Microsoft is having trouble connecting your account. Please try again later or contact couponer@adcore.com"
    let severity = 'error'
    let summary = 'We apologize for the inconvenience.'
    if (this.apiCallService.msLinkTaskId && this.apiCallService.msLinkTaskId.length) {
      this.loading = true;
      const taskId = this.apiCallService.msLinkTaskId;
      let timeout = true;
      this.apiCallService.statusPoll(this.page ).pipe()
        .subscribe(
          {
            next: (res) => {
              timeout = false;
              const status = res.status;
              if (status === 'completed') {
                sessionStorage.setItem('microsoftPassedList', JSON.stringify(res.passedList));
                this.router.navigate(['/ms/step2']);
              } else {
                switch (status) {
                  case 'accepted':
                    detail = "The account is already linked to Couponer's manager account.";
                    severity = 'success';
                    summary = 'Great news!';
                    break
                  case 'spend':
                    detail = "Account must have recorded spending of $1 in the last 30 days to benefit from the coupon. Please try linking a different account or create a new one.";
                    severity = 'warn';
                    break
                  case 'oversize':
                    detail = "The MCC you’re attempting to connect is too large to benefit from the coupons. Please consider linking individual accounts instead.";
                    severity = 'warn';
                    break
                  case 'timeout':
                    detail = "Task timeout. Please try again later.";
                    severity = 'error';
                    break
                  default:
                    console.log(`Connect Error: ${res.errInfo}`);
                }
                this.messageService.add({
                  key: 'msgTemplate',
                  severity: severity,
                  summary: summary,
                  detail: detail,
                  closable: true,
                  sticky: true
                })
              }
            },
            error: (reason) => {
              console.error({reason});
            },
            complete: () => {
              this.loading = false;
              this.apiCallService.deleteMsLinkTaskId();
              if (timeout) {
                this.messageService.add({
                  key: 'msgTemplate',
                  severity: 'error',
                  summary: '',
                  detail: "Task timeout. Please try again later.",
                  closable: true,
                  sticky: true
                });
                this.apiCallService.timeoutTask(taskId, sessionStorage.getItem('microsoftToken'));
              }
            }
          }
        );
    }
  }

  got_it(action: string): void {
    this.navigationService.canNavigateToStep3Microsoft = true;
    this.router.navigate(['/ms/step3'], { queryParams: { action: action } });
  }

  close(event: any): void {
    if (event.message.severity === 'success') {
      this.got_it('link');
    }
  }
}
