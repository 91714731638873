<div class="coupon-layout microsoft-coupon fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="microsoft-image">
      <div class="image cn">
          <div class="shadow"></div>
          <div class="on-top-shadow">
              <div class="adcore-logo white"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
              <span class="microsoft-tag-box"><a class="microsoft-tag partner" href="https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc" target="_blank"></a></span>
              <span class="microsoft-tag-box"><a class="microsoft-tag global-parthner" href="https://about.ads.microsoft.com/en-us/blog/post/june-2023/2023-microsoft-advertising-global-partner-award-winners#:~:text=Global%20Channel%20Partner,across%20the%20board" target="_blank"></a></span>
              <div class="content bold">
                  <div>获得 400 美元的 Microsoft Ads优惠券</div>
                  <div>只需两个简单的步骤</div>
              </div>
              <div class="mic-eligible-btn"><button (click)="goToEligibleLink('microsoft')">仅适用于符合条件的账户</button></div>
              <div class="link-acc cn">
                  <app-link-steps [accountLinked]="false" [step1Text]="'关联您的Microsoft Ads 帐户'" [step1SecText]="''" [step2Text]="'兑换您的400 美元优惠券'" [step2SecText]="''" [stepMiddleText]="'Checking your eligibility...'" [showMiddleStep]="loading" [accountLinked]="loading"></app-link-steps>
                  <div class="buttons" *ngIf="!loading">
                      <div>
                          <div><p-button styleClass="link-btn bold" label="关联您的 Microsoft Ads帐户" (onClick)="openRecaptcha('link')"></p-button></div>
                          <span class="text">或者</span>
                          <span><a class="create-btn" (click)="openRecaptcha('create')">创建新账户</a></span>
                      </div>
                  </div>
                  <div class="loader-box acc" *ngIf="loading">
                    <div class="acc-loader-green"></div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <app-metrics [merticData]="metricData"></app-metrics>
  <app-video-player videoPath="https://www.youtube.com/embed/sLjaUgG69DM" [type]="'microsoft'"></app-video-player>
  <app-powered [lang]="'cn'" (linkBtnEvent)="openRecaptcha('link')" [isFirstStep]="true" [type]="'microsoft'"></app-powered>
  <app-questions [listItemsType]="'cnMicrosoft'" [title]="'常见问题'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>
  <app-footer [lang]="'cn'"></app-footer>
  </div>
</div>
<p-dialog [(visible)]="showDialog" styleClass="create-acc-dialog" [modal]="true" [blockScroll]="true">
  <ng-template pTemplate="header">
    {{dialogHeader}}
    <i class="success-icon" *ngIf="dialogHeader === 'Account created successfully'"></i>
  </ng-template>
  <p>{{dialogBody}}</p>
  <ng-template pTemplate="footer">
    <p-button *ngIf="redeemButton" label="Got it" [disabled]="loading" (onClick)="got_it('create')" styleClass="primary-btn"></p-button>
  </ng-template>
</p-dialog>
<p-toast key="msgTemplate" position="center"  (onClose)="close($event)">
    <ng-template let-message pTemplate="message">
      <app-msg-template [message]="message" (confirm)="got_it('link')">
        <div><p>{{message.detail}}</p></div>
      </app-msg-template>
    </ng-template>
</p-toast>
