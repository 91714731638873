import { Component, Input } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CouponSelectionDialogComponent } from '../coupon-selection-dialog/coupon-selection-dialog.component';
import { DataServiceService } from '../../helpers/data.service';
import {lastValueFrom, Subject, takeUntil} from "rxjs";
import {ApiCallService} from "../../helpers/api-call.service";
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-coupon-selection',
  templateUrl: './coupon-selection.component.html',
  styleUrl: './coupon-selection.component.scss'
})
export class CouponSelectionComponent {
  @Input() type:string = '';
  @Input() title: string = '';
  ref: DynamicDialogRef | undefined;
  isDialogVisible:boolean=false;
  responsiveOptions = [];
  componentDestroyed: Subject<boolean> = new Subject();
  couponListbyBrand:any = [];
  couponArray:any = [];
  score: number | undefined;

  constructor(public dialogService: DialogService, public data: DataServiceService,
              private apiCallService: ApiCallService, private messageService: MessageService,) {
    this.couponArray = this.data.couponArray;
    this.getCouponListByBrand();
  }

  async ngOnInit() {
    const init$ = this.apiCallService.init(this.data.headerMenuSelect.label);
    const response = await lastValueFrom(init$);
    if (response.token) {
      sessionStorage.setItem('token', response.token);
    }
  }

  getCouponListByBrand():any {
    if (this.type && this.couponArray) {
      this.couponListbyBrand = this.couponArray.filter((obj: any) => obj.type === this.type);
    }
    if (this.couponListbyBrand && this.couponListbyBrand[0]) {
      let obj = this.data.selectedCoupon;
      if (obj && !obj.id && this.couponListbyBrand) {
        this.data.changeSelectedCoupon(this.couponListbyBrand[0]);
      }
    }
    return this.couponListbyBrand;
  }

  checkSelection() {
    let obj = this.data.selectedCoupon;
    if (obj && !obj.id && this.couponListbyBrand) {
      this.data.changeSelectedCoupon(this.couponListbyBrand[0]);
      console.log('sdas :' +this.couponListbyBrand[0]);
    }
  }

  async openRequestCouponDialog(): Promise<void> {
    const ipValidate$ = this.apiCallService.ipValidate(this.data.headerMenuSelect.label);
    const response = await lastValueFrom(ipValidate$);
    if (response.enabled) {
      const token = await this.apiCallService.executeRecaptcha('redeem');
      if (token) {
        const params = {
          token: token,
          sessionToken: sessionStorage.getItem('token'),
          action: 'redeem'
        };
        await this.getRecaptcha(params);
      }
    }
    else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "You've hit today's limit for coupon redeem. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!.",
        data: "You've hit today's limit for coupon redeem. Feel free to explore other media channel coupons, or try again tomorrow. Thanks!",
        closable: true,
        sticky: true
      });
    }
  }

  async getRecaptcha(params: any) {
    const recaptchaAssessment = this.apiCallService.recaptchaAssessment(params);
    const recaptcha_response = await lastValueFrom(recaptchaAssessment);
    this.score = recaptcha_response.score;
    if (this.score && this.score >= 0.8) {
      let obj = this.data.selectedCoupon;
      obj.score = this.score;
      this.ref = this.dialogService.open(CouponSelectionDialogComponent, {
          styleClass: 'coupon-selection-dialog',
          modal: true,
          draggable: false,
          resizable: false,
          data: obj
        }
      );
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "High risk indicated by Google reCAPTCHA, please contact us.",
        data: "High risk indicated by Google reCAPTCHA, please contact us.",
        closable: true,
        sticky: true
      });
    }
  }
}
