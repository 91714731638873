import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {lastValueFrom, Subject} from 'rxjs';
import {WindowRefService} from "../../helpers/windowref.service";
import translation from "../cn-google-coupon-step1/messageTranslation.json";
import {MessageService} from 'primeng/api';
import {ApiCallService} from "../../helpers/api-call.service";
import {NavigationService} from "../../helpers/navigation.service";
import { DataServiceService } from 'src/app/helpers/data.service';

@Component({
  selector: 'app-cn-google-coupon-step2',
  templateUrl: './cn-google-coupon-step2.component.html',
  styleUrls: ['./cn-google-coupon-step2.component.scss']
})
export class CnGoogleCouponStep2Component implements OnInit, OnDestroy {
  loading: boolean = false;
  componentDestroyed: Subject<boolean> = new Subject();
  geo: string = 'cn';
  openTabIndex:any = undefined;
  metricData:any = {
    link: {
      amount: '3,000',
      text: '帐户已链接',
    },
    redeemed: {
      amount: '2,250',
      text: '优惠券已兑换',
    },
    saved: {
      amount: '$955,000',
      text: '已节省',
    }
  };
  page = 'google';

  constructor(private router: Router, private winRef: WindowRefService, private navigationService: NavigationService,
              private messageService: MessageService, private apiCallService: ApiCallService, public data:DataServiceService) {
                this.data.setHeaderMenuSelection('2');
               }

  ngOnInit(): void {
    this.navigationService.canNavigateToStep3Google = false;
    this.winRef.reportGtagEventNew('pageViewStep2');
    document.body.scrollTop = 0;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  goToEligibleLink(channel:string) {
    this.data.goToEligibleLink(channel);
   }
   
  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

  async RedeemCoupon() {
    const info = sessionStorage.getItem('googleInfo');
    const sessionToken = sessionStorage.getItem('googleToken');
    const passedList = sessionStorage.getItem("googlePassedList")
    if (info && sessionToken && passedList) {
      const infoJson = JSON.parse(info);
      const refreshToken = infoJson.refresh_token;
      const managerLinks = JSON.parse(passedList);
      if (refreshToken && managerLinks && managerLinks.length) {
        this.loading = true;
        const params = {
          refresh_token: refreshToken,
          manager_links: managerLinks,
          session_token: sessionToken,
          geo: this.geo,
          utm_source: infoJson.utm_source,
          utm_medium: infoJson.utm_medium,
          page:this.page
        };
        const accept$ = this.apiCallService.accept(params, 'google');
        const response = await lastValueFrom(accept$);
        this.loading = false;
        if (response && response.result == 'success'){
          sessionStorage.removeItem("googleInfo");
          sessionStorage.removeItem("googleToken");
          sessionStorage.removeItem('googlePassedList');
          this.navigationService.canNavigateToStep3Google = true;
          this.router.navigate(['/g-cn/step3']);
        } else {
          let error = response.error;
          let severity = error.severity;
          let summary = '我们为造成的不便道歉。';
          if (severity === 'success') {
            summary = '好消息!';
          }
          if (severity === 'warn') {
            summary = '';
          }
          this.messageService.add({
            key: 'msgTemplate',
            severity: severity,
            summary: summary,
            detail: error.info,
            data:translation[error.info as keyof typeof translation],
            closable: true,
            sticky: true
          });
        }
      }
    }
  }

  got_it(): void {
    this.navigationService.canNavigateToStep3Google = true;
    this.router.navigate(['/g-cn/step3'], { queryParams: { action: 'link' } });
  }
}
