<div>
  <div class="text-center mb-3">
    <div>We’ve sent a 6-digit code to your email: </div>
    <div>{{verifyInfo.email}}</div>
    <div>Enter the code below to continue.</div>
  </div>
  <div class="field fluid mb-3" [ngClass]="{'invalid-border': codeError}">
    <div class="card flex justify-content-center">
      <p-inputOtp [(ngModel)]="code" [integerOnly]="true" [length]="6" class="verify-code" (onChange)="onCodeChange()"/>
    </div>
    <small class="login-error mb-1" *ngIf="codeError">Invalid verification code</small>
    <small class="mb-1" *ngIf="showResentResult">{{resentResult}}</small>
    <div class="action-container">
      <div class="resend-container">
        <p-button label="Resend code" styleClass="p-button-link" (onClick)="send2faRequest()" [disabled]="inProcess || resendTimes >= 6 || isResendDisabled"></p-button>
        <div *ngIf="isResendDisabled">({{countdown}} seconds)</div>
      </div>
      <p-button label="Submit Code" styleClass="submit-code-button" (onClick)="submit()" [disabled]="inProcess" />
    </div>
  </div>
</div>

