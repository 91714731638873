<div class="coupon-layout google-coupon cn fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="adcore-logo"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
    <a class="google-tag"  href="https://www.google.com/partners/agency?id=3987411150" target="_blank"><i></i></a>
    <div class="content">
        <span>只需两个简单的步骤即可获得最多价值 <span class="bold">3000</span> 港币的</span>
        <div>Google Ads 优惠券</div>
     </div>
     <div class="google-eligible-btn"><button (click)="goToEligibleLink('google')">仅适用于符合条件的账户</button></div>
    <div class="link-acc">
        <app-link-steps [step1Text]="'关联您的 Google Ads 帐户'" [step2Text]="'兑换您的 3000 港币优惠券'" [stepMiddleText]="'Checked'" [showMiddleStep]="true" [accountLinked]="true" [isMiddleChaked]="true"></app-link-steps>
        <div class="buttons">
            <div>
                <p-button styleClass="link-btn" (onClick)="RedeemCoupon()" [disabled]="loading">
                    <div></div>
                </p-button>
                <div class="loader-box green" *ngIf="loading">
                    <div class="loader">
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="text">关联您的 Google Ads 帐户，以领取您的优惠券。</div>
        </div>
  </div>
  <app-metrics [merticData]="metricData"></app-metrics>
  <app-video-player videoPath="https://www.youtube.com/embed/i4kV_V0qby4"></app-video-player>
  <app-powered [lang]="'cn'" [type]="'google'"></app-powered>
  <app-questions [listItemsType]="'cnGoogle'" [title]="'常见问题'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>
  <app-footer [lang]="'cn'"></app-footer>
  </div>
</div>
<p-toast key="msgTemplate" position="center">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message" (confirm)="got_it()">
      <div><p>{{message.data}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
