import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { QuestionsComponent } from './shared/questions/questions.component';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { CarouselModule } from 'primeng/carousel';
import { ItemAppComponent } from './shared/item-app/item-app.component';
import { ItemsAppComponent } from './shared/items-app/items-app.component';
import { FormsModule } from '@angular/forms';
import { LinkStepsComponent } from './shared/link-steps/link-steps.component';
import { GoogleCouponStep1Component } from './google-coupon/google-coupon-step1/google-coupon-step1.component';
import { GoogleCouponStep2Component } from './google-coupon/google-coupon-step2/google-coupon-step2.component';
import { GoogleCouponStep3Component } from './google-coupon/google-coupon-step3/google-coupon-step3.component';
import { MicrosoftCouponStep1Component } from './microsoft-coupon/microsoft-coupon-step1/microsoft-coupon-step1.component';
import { MicrosoftCouponStep2Component } from './microsoft-coupon/microsoft-coupon-step2/microsoft-coupon-step2.component';
import { MicrosoftCouponStep3Component } from './microsoft-coupon/microsoft-coupon-step3/microsoft-coupon-step3.component';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {WindowRefService} from "./helpers/windowref.service";
import {MessageService} from 'primeng/api';
import { CnGoogleCouponStep1Component } from './cn-google-coupon/cn-google-coupon-step1/cn-google-coupon-step1.component';
import { CnGoogleCouponStep2Component } from './cn-google-coupon/cn-google-coupon-step2/cn-google-coupon-step2.component';
import { CnGoogleCouponStep3Component } from './cn-google-coupon/cn-google-coupon-step3/cn-google-coupon-step3.component';
import { CnMicrosoftCouponStep1Component } from './cn-microsoft-coupon/cn-microsoft-coupon-step1/cn-microsoft-coupon-step1.component';
import { CnMicrosoftCouponStep2Component } from './cn-microsoft-coupon/cn-microsoft-coupon-step2/cn-microsoft-coupon-step2.component';
import { CnMicrosoftCouponStep3Component } from './cn-microsoft-coupon/cn-microsoft-coupon-step3/cn-microsoft-coupon-step3.component';
import { AdVideoComponent } from './shared/ad-video/ad-video.component';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import { MsgTemplateComponent } from './shared/msg-template/msg-template.component';
import {SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, MicrosoftLoginProvider} from '@abacritt/angularx-social-login';
import { CreateGoogleAdsAccountComponent } from './shared/dialogs/create-google-ads-account/create-google-ads-account.component';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { MetricsComponent } from './shared/metrics/metrics.component';
import { VideoPlayerComponent } from './shared/video-player/video-player.component';
import { PoweredComponent } from './shared/powered/powered.component';
import { AdminComponent } from './admin/admin.component';
import {ApiCallService} from "./helpers/api-call.service";
import {CookieService} from 'ngx-cookie-service';
import {CardModule} from "primeng/card";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ProgressBarModule} from "primeng/progressbar";
import {HomeComponent} from './home/home.component';
import {CouponBoxComponent} from './shared/coupon-box/coupon-box.component';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {ChipsModule} from "primeng/chips";
import {HeaderComponent} from './shared/header/header.component';
import { RtbComponent } from './rtb/rtb.component';
import { YandexComponent } from './yandex/yandex.component';
import { CouponSelectionComponent } from './shared/coupon-selection/coupon-selection.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputOtpModule } from 'primeng/inputotp';
import { CouponSelectionDialogComponent } from './shared/coupon-selection-dialog/coupon-selection-dialog.component';
import { DvComponent } from './dv/dv.component';
import { CriteoComponent } from './criteo/criteo.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AllCouponsComponent } from './shared/all-coupons/all-coupons.component';
import { MastercardComponent } from './mastercard/mastercard.component';
import { YoutubeCouponStep1Component } from './youtube/youtube-coupon-step1/youtube-coupon-step1.component';
import { YoutubeCouponStep2Component } from './youtube/youtube-coupon-step2/youtube-coupon-step2.component';
import { YoutubeCouponStep3Component } from './youtube/youtube-coupon-step3/youtube-coupon-step3.component';
import { XndrComponent } from './xndr/xndr.component';
import {PhoneComponent} from './shared/phone/phone.component';
import { LinkedinCouponStep1Component } from './linkedin-coupon/linkedin-coupon-step1/linkedin-coupon-step1.component';
import { LinkedinCouponStep2Component } from './linkedin-coupon/linkedin-coupon-step2/linkedin-coupon-step2.component';
import { LinkedinCouponStep3Component } from './linkedin-coupon/linkedin-coupon-step3/linkedin-coupon-step3.component';
import { NetflixCouponStep1Component } from './netflix-coupon/netflix-coupon-step1/netflix-coupon-step1.component';
import { NetflixCouponStep2Component } from './netflix-coupon/netflix-coupon-step2/netflix-coupon-step2.component';
import { NetflixCouponStep3Component } from './netflix-coupon/netflix-coupon-step3/netflix-coupon-step3.component';
import { XandrCouponStep1Component } from './xandr-coupon/xandr-coupon-step1/xandr-coupon-step1.component';
import { XandrCouponStep2Component } from './xandr-coupon/xandr-coupon-step2/xandr-coupon-step2.component';
import { XandrCouponStep3Component } from './xandr-coupon/xandr-coupon-step3/xandr-coupon-step3.component';
import { YahooCouponStep1Component } from './yahoo-coupon/yahoo-coupon-step1/yahoo-coupon-step1.component';
import { YahooCouponStep2Component } from './yahoo-coupon/yahoo-coupon-step2/yahoo-coupon-step2.component';
import { YahooCouponStep3Component } from './yahoo-coupon/yahoo-coupon-step3/yahoo-coupon-step3.component';
import { VerifyCodeComponent} from "./shared/verify-code/verify-code.component";

@NgModule({ declarations: [
        AppComponent,
        MicrosoftCouponStep1Component,
        GoogleCouponStep1Component,
        FooterComponent,
        QuestionsComponent,
        ItemAppComponent,
        ItemsAppComponent,
        GoogleCouponStep2Component,
        GoogleCouponStep3Component,
        MicrosoftCouponStep2Component,
        MicrosoftCouponStep3Component,
        LinkStepsComponent,
        CnGoogleCouponStep1Component,
        CnGoogleCouponStep2Component,
        CnGoogleCouponStep3Component,
        CnMicrosoftCouponStep1Component,
        CnMicrosoftCouponStep2Component,
        CnMicrosoftCouponStep3Component,
        AdVideoComponent,
        MsgTemplateComponent,
        CreateGoogleAdsAccountComponent,
        MetricsComponent,
        VideoPlayerComponent,
        PoweredComponent,
        AdminComponent,
        HomeComponent,
        CouponBoxComponent,
        HeaderComponent,
        RtbComponent,
        YandexComponent,
        CouponSelectionComponent,
        CouponSelectionDialogComponent,
        DvComponent,
        CriteoComponent,
        ThankYouComponent,
        AllCouponsComponent,
        MastercardComponent,
        YoutubeCouponStep1Component,
        YoutubeCouponStep2Component,
        YoutubeCouponStep3Component,
        XndrComponent,
        PhoneComponent,
        XandrCouponStep1Component,
        XandrCouponStep2Component,
        XandrCouponStep3Component,
        NetflixCouponStep1Component,
        NetflixCouponStep2Component,
        NetflixCouponStep3Component,
        LinkedinCouponStep1Component,
        LinkedinCouponStep2Component,
        LinkedinCouponStep3Component,
        YahooCouponStep1Component,
        YahooCouponStep2Component,
        YahooCouponStep3Component,
        VerifyCodeComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ButtonModule,
        ToastModule,
        AccordionModule,
        CarouselModule,
        FormsModule,
        DialogModule,
        InputOtpModule,
        DynamicDialogModule,
        SocialLoginModule,
        DropdownModule,
        InputTextModule,
        ProgressSpinnerModule,
        GoogleSigninButtonModule,
        CardModule,
        InputTextareaModule,
        ProgressBarModule,
        MenuModule,
        ChipsModule,
        TooltipModule,
        CheckboxModule], providers: [WindowRefService, MessageService, DialogService, ApiCallService,
        CookieService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('230530490489-nqm7nta5rcvsbnmutuu62kl8edgnrjo9.apps.googleusercontent.com', {
                            oneTapEnabled: false, // default is true
                            scopes: ['https://www.googleapis.com/auth/userinfo.email',
                                'https://www.googleapis.com/auth/userinfo.profile', 'openid']
                        })
                    },
                    {
                        id: MicrosoftLoginProvider.PROVIDER_ID,
                        provider: new MicrosoftLoginProvider('d14a8ef6-f703-4977-a1ca-9a4593bca51c', {})
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
